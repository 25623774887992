<template>
  <div class='content' :elevation='24'>
    <v-alert dense class='alerts' :value='tipsInfo' :type='tipsInfoType'>{{ tipsInfoContent }}</v-alert>
    <div class='logink'>
      <div class='logink_left_img'>
        <v-img class='left_img' src='../../assets/login_left.png' />
      </div>
      <div class='logink_right'>
        <div class='logink_title'>TRIZ</div>
        <vv-observer ref='vob' v-slot='{ validate }'>
          <div class='login_con'>
            <v-img class='longin_search_img' src='../../assets/username.png'></v-img>
            <vv-provider v-slot='{ errors }' rules='required' slim>
              <input placeholder='请输入用户名' v-model='username' class='longin_search' :error-messages='errors' />
              <!-- <v-text-field class="longin_search" label="请输入手机号" solo v-model="username"  :error-messages="errors"></v-text-field> -->
            </vv-provider>
          </div>
          <div class='login_con'>
            <v-img class='longin_search_img' src='../../assets/password.png'></v-img>
            <vv-provider v-slot='{ errors }' rules='required' slim>
              <input
                placeholder='请输入密码'
                v-model='password'
                type='password'
                class='longin_search'
                :error-messages='errors'
              />
            </vv-provider>
          </div>
          <!-- <div class="login_con" v-if="isshowdept">
            <vv-provider v-slot="{ errors }" rules="required" slim>
              <v-select  class="longin_search" :items="deptList" label="请选择" item-text="storeName" item-value="deptId" solo v-model="deptId" :error-messages="errors"></v-select>
              <el-select v-model="deptId" clearable placeholder="请选择组织" class="selectstyle">
                <el-option
                  v-for="(item, index) in deptList"
                  :key="index"
                  :label="item.storeName"
                  :value="item.deptId"
                  :error-messages="errors"
                />
              </el-select>
            </vv-provider>
          </div> -->
          <div style="margin-left:24px;'">
			  <v-checkbox v-model='checkbox' label='记住密码'></v-checkbox>
          </div>
		  <div >
			  <div class='login_btn' :elevation='24' @click='loginInfo(validate)'>登录</div>
			  <!-- <div class='login_btn' @click='personalRegister()'>个人注册</div>
			  <div class='login_btn' @click='enterpriseRegister()'>企业注册</div> -->
		  </div>
			<!-- 注册组织 -->
          <v-dialog v-model='addOnedialog' max-width='620px'>
          
          	<v-card>
          		<v-card-title>
          			<span class='text-h5' v-if="isRe">注册组织</span>
					<span class='text-h5' v-if="!isRe">个人注册</span>
          		</v-card-title>
          		<v-card-text>
          			<v-container>
          				<v-form ref='formUserOne' v-model='validOne' lazy-validation>
          					<v-row>
          						<v-col cols='12' style='padding: 0 12px' v-if="isRe">
          							<v-text-field label='组织编码 *' counter='32' maxlength='32' outlined
          								:rules='rules' v-model='addOrganizationSingle.shortCode'
          								required></v-text-field>
          						</v-col>
          						<v-col cols='12' style='padding: 0 12px' v-if="isRe">
          							<v-text-field label='组织名称 *' counter='32' maxlength='32' outlined
          								:rules='rules' v-model='addOrganizationSingle.organizationName'
          								required></v-text-field>
          						</v-col>
          						<v-col cols='12' style='padding: 0 12px' v-if="isRe">
          							<v-text-field label='组织名称缩写 *' counter='32' maxlength='32' outlined
          								:rules='rules' v-model='addOrganizationSingle.organizationShortName'
          								required></v-text-field>
          						</v-col>
          						<v-col cols='12' style='padding: 0 12px' >
          							<v-text-field label='管理员账号 *' counter='32' maxlength='32' outlined
          								:rules='rules' v-model='addOrganizationSingle.userName'
          								required></v-text-field>
          						</v-col>
          						<v-col cols='12' style='padding: 0 12px'>
          							<v-text-field label='管理员密码 *' outlined :rules='rules'
          								v-model='addOrganizationSingle.password' required></v-text-field>
          						</v-col>
								
								<v-col cols='12' style='padding: 0 12px'>
									<v-text-field label='确认密码 *' outlined :rules='rules'
										v-model='addOrganizationSingle.confirmPassword' required></v-text-field>
								</v-col>
          						<v-col cols='12' style='padding: 0 12px'>
          							<v-text-field label='管理员昵称 *' counter='50' maxlength='50' outlined
          								v-model='addOrganizationSingle.nickName' :rules='rules'
          								required></v-text-field>
          						</v-col>
          						<v-col cols='12' style='padding: 0 12px'>
          							<v-text-field label='管理员邮箱' counter='50' outlined
          								v-model='addOrganizationSingle.email' :rules='emailRules' max='20'
          								maxlength='20' required></v-text-field>
          						</v-col>
          						<v-col cols='12' style='padding: 0 12px'>
          							<v-text-field label='管理员电话号码 *' maxlength='15' counter='15'
          								:rules='phoneRules' outlined v-model='addOrganizationSingle.phone'
          								required></v-text-field>
          						</v-col>
          					</v-row>
          				</v-form>
          			</v-container>
          		</v-card-text>
          		<v-card-actions>
          			<v-spacer></v-spacer>
          			<v-btn color='#ccc darken-4' @click='close'> 取消</v-btn>
          			<v-btn color='blue darken-2' style='color: #fff' @click='endRegister'> 确认</v-btn>
          		</v-card-actions>
          	</v-card>
          </v-dialog>
        </vv-observer>
      </div>
    </div>
    <div style='position: absolute;bottom: 0;width: 100%;height: 30px;text-align: center;color: #999aaa'><a href="https://beian.miit.gov.cn/" target="_blank">辽ICP备2022004688号</a></div>
  </div>
</template>

<script>
import { getCheckbox, getPaw, getUser } from '@/utils/auth'
import {
		register,
	} from '@/api/project'
export default {
  data() {
    return {
      tipsInfo: false,
      tipsInfoType: 'success',
      tipsInfoContent: '',
      checkbox: getCheckbox() ? getCheckbox() : false,
      username: getUser() ? getUser() : '',
      password: getPaw() ? getPaw() : '',
      deviceId: '',
      deptList: [],
      isshowdept: false,
	  addOnedialog:false,
	  validOne: true,
	  isRe:true,
	 
	  addOrganizationSingle: {
	  	organizationName: '',
	  	organizationShortName: '',
	  	shortCode: '',
	  	email: '',
	  	password: '',
		confirmPassword: '',
	  	phone: '',
	  	nickName: '',
	  	userName: ''
	  },
	  rules: [(value) => !!value || '请输入'], // 验证
	  emailRules: [v => v ? /.+@.+\..+/.test(v) || '邮箱格式不正确' : !v],
	  phoneRules: [v => v ? /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$|^(0\d{2,3})-?(\d{7,8})$/
	  	.test(v) || '电话号码格式不正确' : !v
	  ],
    }
  },
  created() {
    this.cans()
  },
  watch: {
    username(val) {
      if (val == getUser() && this.password == getPaw()) {
        this.checkbox = true
      } else {
        this.checkbox = false
        this.password = ''
      }
    },
    password(val) {
      if (val == getPaw() && this.username == getUser()) {
        this.checkbox = true
      }
    }
  },
  methods: {
    //获取浏览器唯一标识
    cans() {
      var canvas = document.createElement('canvas')
      var ctx = canvas.getContext('2d')
      var txt = 'http://test-triz.ctsource.cn'
      ctx.textBaseline = 'top'
      ctx.font = '14px \'Arial\''
      ctx.textBaseline = 'tencent'
      ctx.fillStyle = '#f60'
      ctx.fillRect(125, 1, 62, 20)
      ctx.fillStyle = '#069'
      ctx.fillText(txt, 2, 15)
      ctx.fillStyle = 'rgba(102, 204, 0, 0.7)'
      ctx.fillText(txt, 4, 17)

      var b64 = canvas.toDataURL().replace('data:image/png;base64,', '')
      var bin = atob(b64)
      var crc = this.bin2hex(bin.slice(-16, -12))
      //var crc = bin.slice(-16,-12);
      this.deviceId = crc
      console.log('唯一标识', crc)
    },

    bin2hex(str) {
      var result = ''
      for (let i = 0; i < str.length; i++) {
        result += this.int16_to_hex(str.charCodeAt(i))
      }
      return result
    },

    int16_to_hex(i) {
      var result = i.toString(16)
      var j = 0
      while (j + result.length < 4) {
        result = '0' + result
        j++
      }
      return result
    },
	//个人注册 
	personalRegister(){
		this.isRe=false;
		this.addOrganizationSingle.organizationName = ''
		this.addOrganizationSingle.organizationShortName = ''
		this.addOrganizationSingle.shortCode = ''
		this.addOrganizationSingle.email = ''
		this.addOrganizationSingle.password = ''
		this.addOrganizationSingle.confirmPassword = ''
		this.addOrganizationSingle.phone = ''
		this.addOrganizationSingle.nickName = ''
		this.addOrganizationSingle.userName = ''
		this.addOnedialog=true
	},
	// 企业注册
	enterpriseRegister(){
		this.isRe=true;
		this.addOrganizationSingle.organizationName = ''
		this.addOrganizationSingle.organizationShortName = ''
		this.addOrganizationSingle.shortCode = ''
		this.addOrganizationSingle.email = ''
		this.addOrganizationSingle.password = ''
		this.addOrganizationSingle.confirmPassword = ''
		this.addOrganizationSingle.phone = ''
		this.addOrganizationSingle.nickName = ''
		this.addOrganizationSingle.userName = ''
		this.addOnedialog=true
	},
	async endRegister() {
		const checked = await this.$refs.formUserOne.validate()
		if (!checked) return
		console.log(this.addOrganizationSingle.password!=this.addOrganizationSingle.confirmPassword)
		if(this.addOrganizationSingle.password!=this.addOrganizationSingle.confirmPassword){
			this.tipsInfo = true
			this.tipsInfoType = 'error'
			this.tipsInfoContent = "密码不一致"
			this.showRole = false
			setTimeout(() => {
				this.showRole = true
			}, 1)
			setTimeout(() => {
				this.tipsInfo = false
			}, 3000)
			return
		}
		this.username=this.addOrganizationSingle.userName
		this.password=this.addOrganizationSingle.password
		var url = 'auth/admin/enterpriseRegister'
		if(!this.isRe){
			url = 'auth/register'
		}
		console.log(url)
		register(url,this.addOrganizationSingle)
			.then((res) => {
				this.tipsInfo = true
				this.tipsInfoType = 'success'
				this.tipsInfoContent = '注册成功'
				setTimeout(() => {
					this.tipsInfo = false
				}, 2000)
				this.close()
			})
			.catch((e) => {
				console.log(e.response.data)
				let data = e.response.data.message
				if(data=='' || data==null || data==undefined){
					data=e.response.data.data[0].phone
				}
				this.tipsInfo = true
				this.tipsInfoType = 'error'
				this.tipsInfoContent = data
				this.showRole = false
				setTimeout(() => {
					this.showRole = true
				}, 1)
				setTimeout(() => {
					this.tipsInfo = false
				}, 3000)
			})
		
		
	},
	close(){
		this.addOnedialog=false
	},
    async loginInfo(validate) {
      console.log('this.checkbox', this.checkbox)
      const ok = await validate()
      if (!ok) return
      const data = {
        checkbox: this.checkbox,
        username: this.username,
        password: this.password,
        deviceInfo: {
          deviceId: this.deviceId,
          deviceTypeEnum: 'DEVICE_TYPE_WEB'
        }
      }
      await this.$store
        .dispatch('LoginByUsername', data)
        .then(() => {
          this.$router.push({ path: '/my/tools' })
        })
        .catch((error) => {
          let err = error.response.data.message
          this.tipsInfo = true
          this.tipsInfoType = 'error'
          this.tipsInfoContent = err
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
      // if (this.checkbox) {
      //   this.$cookies.set('username', this.username)
      //   this.$cookies.set('password', this.password)
      // } else if (this.$cookies.get('username')) {
      //   this.$cookies.remove('username')
      //   this.$cookies.remove('password')
      // }
    }
  }
}
</script>
<style lang='scss' scoped>
.content {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/background.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.alerts {
  position: fixed;
  top: 30px;
  width: auto;
  align-self: center;
  margin: 0 auto;
  z-index: 9999;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.logink {
  width: 960px;
  height: 460px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 0 4px #ccc;
  border-radius: 10px;
  position: relative;
  top: 16%;

  .logink_left_img {
    margin: 0 75px 0 65px;
    display: inline-block;
    width: 392px;
    height: 242px;

    .left_img {
      width: 392px;
      height: 242px;
    }
  }

  .logink_right {
    display: inline-block;
  }
}

.logink_title {
  font-size: 30px;
  color: #3f81c1;
  font-style: normal;
  font-weight: bold;
  width: 336px;
  margin: 0 auto;
}

.login_con {
  position: relative;
}

.longin_search {
  width: 336px;
  max-width: 336px;
  margin-top: 30px;
  height: 48px;
  background: #eef6ff;
  border-radius: 25px;
  font-size: 14px;
  text-indent: 70px;
  outline: none;
}

.longin_search::-webkit-input-placeholder {
  /* placeholder颜色  */
  color: #999999;
  /* placeholder字体大小  */
  font-size: 14px;
  /* placeholder位置  */
  text-align: left;
}

.longin_search_img {
  position: absolute;
  top: 46px;
  left: 34px;
}

.login_btn {
  width: 336px;
  /*  width: 110px; */
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  margin: 0px 5px;
  background-color: #3f81c1;
  border-radius: 50px;
  cursor: pointer;
  float: left;
}

.wangji {
  width: 306px;
  margin: 0 auto;
  text-align: right;
  color: #5473e8;
  font-size: 16px;
}
</style>
